import { ColumnMapType, ElementMapType, MultiListType } from 'components/DragNDrop';
import { ConfigureViewColumnType } from './ConfigureViewCustomTable';
import { GenericColumnModel } from 'components/Table';

export const getMultiListDataByTableColumns = (visibleColumns: GenericColumnModel[], hiddenColumns: GenericColumnModel[]): MultiListType => {
	const allTableColumns = [...visibleColumns, ...hiddenColumns];

	const elementMap: ElementMapType = allTableColumns.reduce(
		(elementsObject: ElementMapType, tableColumn) => {
			elementsObject[tableColumn.id] = {
				id: tableColumn.id,
				content: tableColumn.title,
				frozen: tableColumn.frozen
			};

			return elementsObject;
		},
		{}
	);

	const availableColumns = hiddenColumns.map((item) => item.id);
	const selectedColumns = visibleColumns.map((item) => item.id);

	const columnMap: ColumnMapType = {
		available: { id: 'available', title: 'i18n.configureView.hidden', elementIds: availableColumns },
		selected: { id: 'selected', title: 'i18n.configureView.visible', elementIds: selectedColumns },
	};

	return { elementMap, columnMap, columnOrder: ['available', 'selected'] };
}

export const getTableColumnsDataByMultiList = (multiList: MultiListType, visibleColumns: GenericColumnModel[], hiddenColumns: GenericColumnModel[]): ConfigureViewColumnType[] => {
	const allTableColumns = [...visibleColumns, ...hiddenColumns];

	const result: ConfigureViewColumnType[] = [];
	const multiListKeys = [
		...multiList.columnMap['selected'].elementIds,
		...multiList.columnMap['available'].elementIds,
	];
	multiListKeys.forEach((key) => {
		const tableColumnElement = allTableColumns.find((tableColumn) => tableColumn.id === key)!

		const newTableColumnElement = {
			...tableColumnElement,
			visible: multiList.columnMap['selected'].elementIds.includes(key),
		}

		result.push(newTableColumnElement);
	});

	return result;
}
