import { createContext } from "react";

// when there is some validation problem on form, that is not strictly on one field, backend is sending it thru property with id ''
export const globalErrorKey = '';

export type ErrorType = {
	message: string
	i18nArgs?: string[]
};

// field error is ErrorType
// delta error is ErrorsType[]
// global error is always ErrorType so defined separately for TS not to complain
export type ErrorsType = {
	[key: string]: ErrorType | ErrorsType[] | undefined
} & {
	[globalErrorKey]?: ErrorType | undefined
};

export type ValidatorFunctionType = (value: any, values: any, id: string) => ErrorType | ErrorsType[] | undefined;

export type ValuesType = {
	[key: string]: any
}

export interface IFormContext {
	values: ValuesType
	errorsMap: ErrorsType
	onFieldChange?: (id: string, value: any, dependantValues: ValuesType) => void
	validateField?: (id: string, value?: any) => void
	disabled?: boolean
	registerValidators?(id: string, validatorFunctions: Array<ValidatorFunctionType>): void
	setFieldValue?(id: string, fieldValue: any): void
	setFieldError?(id: string, fieldError: ErrorsType[] | undefined): void // only TableField uses it so type ErrorsType[] is enough
}

export const FormContext = createContext<IFormContext>({
	values: {},
	errorsMap: {}
});
