import { ErrorsType, globalErrorKey } from "components/Form/models";
import { FieldMessage } from "components/Form/addons";
import { RowContainer } from "components/Layout";

type Props = {
	values: any
	renderItem(item: any): any
	errorsMap: ErrorsType
}

export const CustomItem = ({ values, renderItem, errorsMap }: Props) => {
	const content = renderItem(values);

	return (
		<>
			{content}
			{errorsMap && errorsMap[globalErrorKey] &&
				<RowContainer justifyContent='flex-end'>
					<FieldMessage error={errorsMap[globalErrorKey]} />
				</RowContainer>
			}
		</>
	)
}
