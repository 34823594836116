import { Subtract } from 'utility-types';
import { RangeMonthDateType, RangeMonthPicker, RangeMonthPickerProps } from '../controls/DatePicker/RangeMonthPicker';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const RangeMonthField = (props: Subtract<RangeMonthPickerProps, ControlsCommonProps<RangeMonthDateType>> & FieldProps) => {
    return (
        <Field
            {...props}
            Control={RangeMonthPicker}
        />
    )
}
