import { GenericFilterOperationEnum } from 'services/tenantManagementService';

export const formatFilterOperation = (filterOperation?: GenericFilterOperationEnum) => {
	switch(filterOperation) {
		case GenericFilterOperationEnum.EQUALS:
			return 'i18n.label.filter.operation.equals'
		case  GenericFilterOperationEnum.LIKE:
			return 'i18n.label.filter.operation.like'
		case GenericFilterOperationEnum.LIKE_STARTSWITH:
			return 'i18n.label.filter.operation.likeStartsWith';
		case GenericFilterOperationEnum.LIKE_ENDSWITH:
			return 'i18n.label.filter.operation.likeEndsWith';
		case GenericFilterOperationEnum.NOT_EQUALS:
			return 'i18n.label.filter.operation.notEquals';
		case GenericFilterOperationEnum.GREATER_THAN:
			return 'i18n.label.filter.operation.greaterThan'
		case GenericFilterOperationEnum.GREATER_EQUAL_THAN:
			return 'i18n.label.filter.operation.greaterEqualThan'
		case GenericFilterOperationEnum.LESS_THAN:
			return 'i18n.label.filter.operation.lessThan'
		case GenericFilterOperationEnum.LESS_EQUAL_THAN:
			return 'i18n.label.filter.operation.lessEqualThen'
		default:
			return 'i18n.label.filter.operation.equals'
	}
}
