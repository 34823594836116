import { useCallback } from 'react';
import DatePickerLib from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { CalendarIcon } from 'components/icons/icons';
import { ControlsCommonProps } from '../../fields';
import styles from './datePicker.module.scss';
import { getLastDayOfMonth } from 'utils/dateTimeUtils';

export type RangeMonthDateType = {
	from?: Date
	to?: Date
}

export type RangeMonthPickerProps = ControlsCommonProps<RangeMonthDateType>

export const RangeMonthPicker = ({
	value,
	onChange,
	onBlur,
	disabled,
}: ControlsCommonProps<RangeMonthDateType>) => {
	const onChangeCallback = useCallback(
		([from, to]) => {
			onChange?.({
				from,
				to: getLastDayOfMonth(to)
			})
		},
		[onChange]
	)

	const onCalendarCloseCallback = useCallback(
		() => {
			if (value && value.from && !value.to) {
				onChange?.({
					from: value.from,
					to: getLastDayOfMonth(value.from)
				})
			}

			onBlur?.();
		},
		[onBlur, onChange, value]
	)

	return (
		<div className={styles.container}>
			<DatePickerLib
				className={styles.date_picker}
				selected={value?.from}
				onChange={onChangeCallback}
				// onBlur={onBlurCallback} // doesn't trigger in all cases, so using onCalendarClose instead
				onCalendarClose={onCalendarCloseCallback}
				disabled={disabled}
				selectsRange
				startDate={value?.from}
				endDate={value?.to}
				dateFormat="MM/yyyy"
				showMonthYearPicker
			/>
			<div className={styles.icon}>
				<CalendarIcon width={16} height={16} fill='currentColor' />
			</div>
		</div>
	)
}
