import { ErrorsType, globalErrorKey } from "../../../models";

export const isUniqueFieldNameValidator = (fieldName: string, label: string, values: Array<any>): ErrorsType[] | undefined => {
	const fieldValues = values.map(value => value[fieldName]);
	const duplicates = fieldValues.filter((value, index: number) => fieldValues.indexOf(value) !== index);
	if (duplicates.length > 0) {
		const errors: ErrorsType[] = [];
		errors[values.length - 1] = { [globalErrorKey]: {
			message: 'i18n.validator.unique',
			i18nArgs: [label]
		}}
		return errors;
	}
	return undefined;
}
