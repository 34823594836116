import { envSettings } from "settings/envSettings";

const IS_TENANT_INITIALIZED = 'is_tenant_initialized';
const TENANT_ID = 'tenant_id';
const SUBDOMAIN = 'subdomain'

export const getIsTenantInitialized = () : boolean => {
	return localStorage.getItem(IS_TENANT_INITIALIZED) === 'true';
}

export const setIsTenantInitialized = (isTenantInitialized: boolean) => {
	localStorage.setItem(IS_TENANT_INITIALIZED, isTenantInitialized.toString());
}

export const getTenantId = () : number | undefined => {
	const tenantStringId = localStorage.getItem(TENANT_ID);
	if (tenantStringId === null) {
		return;
	}

	return parseInt(tenantStringId);
}

export const setTenantId = (tenantId: number) => {
	localStorage.setItem(TENANT_ID, tenantId.toString());
}

export const getSubdomainFromUrl = () => {
	const hostName = window.location.hostname;
	if (hostName !== 'localhost' && hostName !== '127.0.0.1') {
		return hostName.slice(0, hostName.length - envSettings.siteDomain.length);
	}

	// localhost
	return envSettings.myTestDomain;
}

export const getSubdomainFromStorage = () => {
	return localStorage.getItem(SUBDOMAIN);
}

export const setSubdomainToStorage = () => {
	const subdomain = getSubdomainFromUrl();
	localStorage.setItem(SUBDOMAIN, subdomain);
}
