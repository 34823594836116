import { Translation } from 'components/Translations/Translation';
import styles from './tableMatrix.module.scss';

export type TableMatrixType = {
	rowHeaders: string[]
	headers: string[]
	data: string[][]
}

export const TableMatrix = ({ rowHeaders, headers, data }: TableMatrixType) => {
	const rows: React.ReactNode[] = [];
	for (let i = 0; i < data.length; i++) {
		rows.push(
			<tr key={i}>
				<th className={styles.row_header}>
					<Translation i18n={rowHeaders[i + 1]} />
				</th>
				{data[i].map((dataValue, index) => (
					<td key={index} className={styles.cell}>
						<Translation i18n={dataValue} />
					</td>
				))}
			</tr>
		);
	}

	return (
		<table>
			<thead>
				<tr>
					<th className={styles.column_header}>
						<div>
							<Translation i18n={headers[0]} />
							</div>
						<hr className={styles.hr} />
						<div>
							<Translation i18n={rowHeaders[0]} />
						</div>
					</th>
					{headers.slice(1).map((column, index) => (
						<th key={index} className={styles.column_header}>
							<Translation i18n={column} />
						</th>
					))}
				</tr>
			</thead>
			<tbody>{rows}</tbody>
		</table>
	)
}
