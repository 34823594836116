import { Translation } from 'components/Translations/Translation';
import styles from './fieldMessage.module.scss';
import { ErrorType } from '../../models';

type Props = {
	error?: ErrorType
	type?: 'error' | 'info' | 'warning'
}

export const FieldMessage = ({ error, type = 'error' }: Props) => {
	return (
		<>
			{error && (
				<div className={`${styles.container} ${styles[type]}`}>
					<Translation i18n={error.message} args={error.i18nArgs} />
				</div>
			)}
		</>
	)
}
