import { useGetTranslation } from './translationUtils';

type Props = {
	// sometimes i18n is undefined somehow, so added "| undefined" just in case
	i18n: string | undefined
	args?: string[]
}

export const Translation = ({ i18n, args = [] }: Props) => {
	const getTranslation = useGetTranslation();

	if (i18n === undefined) {
		return <></>
	}

	let translation = i18n;

	if (translation.startsWith('i18n')) {
		translation = getTranslation(i18n, args)
	}

	return <span>{translation}</span>
}
