import { ControlsCommonProps } from "components/Form/fields";
import { useMemo } from "react";
import { SemaphoreItem, SemaphoreItemType } from "./SemaphoreItem";
import styles from './semaphore.module.scss'
import { Popup, POSITION } from "components/Popup";
import { InfoSquaredIcon } from "components/icons/icons";
import { Translation } from "components/Translations/Translation";

export type SemaphoreProps = ControlsCommonProps<string | number | boolean> & {
	colors?: SemaphoreItemType[]
	withExplanation?: boolean
}

export const Semaphore = ({ colors, value, onChange, disabled, withExplanation }: SemaphoreProps) => {
	const itemContent = useMemo(
		() => {
			return colors?.map((item) => {
				return (
					<SemaphoreItem
						key={item.id.toString()}
						{...item}
						isSelected={value === item.id}
						onClick={onChange}
						disabled={disabled}
					/>
				)
			})
		},
		[colors, value, onChange, disabled]
	);

	const explanationMemo = useMemo(
		() => {
			return colors?.map((item) => {
				return (
					<div key={item.id.toString()} className={styles.explanation_item}>
						<span className={styles.circle} style={{ backgroundColor: item.color }} />
						<Translation i18n={item.explanation || item.text} />
					</div>
				)
			})
		},
		[colors]
	)

	return (
		<div className={styles.wrapper}>
			{itemContent}

			{withExplanation &&
				<div className={styles.explanation}>
					<Popup
						content={explanationMemo}
						position={POSITION.RIGHT_CENTER}
					>
						<InfoSquaredIcon width={18} height={18} fill='currentColor' fillOpacity='0.8' />
					</Popup>
				</div>
			}
		</div>
	)
}
