import { envSettings } from "settings/envSettings";
import { productionConfig } from "./productionConfig";
import { stagingConfig } from "./stagingConfig";

export type Config = {
	Sentry?: {
		dsn: string
	}
}

let config: Config = stagingConfig;

if (envSettings.stage === 'production') {
	config = productionConfig;
}

export { config }
