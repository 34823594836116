import { toast, ToastOptions } from 'react-toastify';
import { CheckIcon, InfoIcon, WarningIcon } from 'components/icons/icons';
import { NotificationContent } from './NotificationContent';

const defaultOptions = {
	hideProgressBar: true,
	draggable: false
}

type NotificationArg = {
	message: string
	i18nArgs?: string[]
	route?: string
	options?: ToastOptions
}

type Notification = {
	info: (arg: NotificationArg) => void
	success: (arg: NotificationArg) => void
	warning: (arg: NotificationArg) => void
	error: (arg: NotificationArg) => void
}

export const notification: Notification = {
	info: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={InfoIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.INFO,
				...options
			}
		);
	},
	success: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={CheckIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.SUCCESS,
				...options
			}
		);
	},
	warning: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={WarningIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.WARNING,
				autoClose: false,
				...options
			}
		);
	},
	error: ({ options, ...props }) => {
		toast(
			<NotificationContent {...props} Icon={WarningIcon} />,
			{
				...defaultOptions,
				type: toast.TYPE.ERROR,
				autoClose: false,
				...options
			}
		);
	}
};
