import { Droppable } from 'react-beautiful-dnd';
import { ElementType } from './models';
import { FrozenElement } from './FrozenElement';
import styles from './styles.module.scss'
import { Element } from './Element';
import { Translation } from 'components/Translations/Translation';

type Props = {
	id: string
	title: string
	elements: ElementType[]
}

export const Column = ({ id, title, elements }: Props) => {
	const frozenStartElements: ElementType[] = [];
	const frozenEndElements: ElementType[] = [];
	const draggableElements: ElementType[] = [];

	for (const element of elements) {
		if (element.frozen === 'start') {
			frozenStartElements.push(element);
		} else if (element.frozen === 'end') {
			frozenEndElements.push(element);
		} else {
			draggableElements.push(element);
		}
	}

	return (
		<>
			<h5>
				<Translation i18n={title} />
			</h5>
			{frozenStartElements.map((element) => (
				<FrozenElement key={element.id} {...element} />
			))}
			<Droppable droppableId={id}>
				{(provided, snapshot) => (
					<div
						className={`${styles.column} ${snapshot.isDraggingOver ? styles.dragging_over : ''}`}
						ref={provided.innerRef}
						{...provided.droppableProps}
					>
						{draggableElements.map((element, index) => (
							<Element key={element.id} {...element} index={index + frozenStartElements.length} />
						))}
					</div>
				)}
			</Droppable>
			{frozenEndElements.map((element) => (
				<FrozenElement key={element.id} {...element} />
			))}
		</>
	)
}
