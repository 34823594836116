import { Provider } from 'react-redux';
import store from 'base/reducer/store';
import { ThemeProvider } from 'features/ThemeProvider';
import { PageRouter } from 'pages';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'features/ErrorBoundary';
import { TranslationsProvider } from 'components/Translations/TranslationsProvider';
import { ToastContainer } from 'react-toastify';

export const AppContainer = () => {
	return (
		<ErrorBoundary location='AppContainer' fallbackProps={{ showButtons: false }}>
			<Provider store={store}>
				<TranslationsProvider>
					<ThemeProvider>
						<BrowserRouter>
							<PageRouter />
						</BrowserRouter>
					</ThemeProvider>
					<ToastContainer />
				</TranslationsProvider>
			</Provider>
		</ErrorBoundary>
	)
}
