import { ErrorsType, FieldProps, FormContext, IFormContext, ValidatorFunctionType } from 'components/Form';
import { ADD_VALIDATOR_TYPE, useRegisteredValidatorsReducer } from 'components/Form/useRegisteredValidatorsReducer';
import { useCallback, useContext, useEffect } from 'react';
import { ActiveModule, ModuleActivityEnum } from 'services/tenantManagementService';
import ProjectActiveModules from './ProjectActiveModules';

export type ProjectActiveModulesCommonProps = /*ControlsCommonProps<any[]> &*/ {
	value?: ActiveModule[]
	onChange?(index: number, id: string, value: any): void
	disabled?: boolean
	errors: ErrorsType[]
	registerValidators(index: number, id: string, validatorFunctions: ValidatorFunctionType[]): void
	validateField(index: number, id: string, value?: any): void
}

export const ProjectActiveModulesField = ({ id, validator, disabled, inline, ...rest }: FieldProps) => {
	const [registeredValidators, dispatchRegisteredValidators] = useRegisteredValidatorsReducer();
	const context = useContext<IFormContext>(FormContext);
	const registerValidatorsInContext = context.registerValidators; // eslint is complaining (in useEffect dependencies) when using context.registerValidators

	const validateFieldCallback = useCallback(
		(id: string, rowValues: any) => {
			const currentValue = rowValues[id];

			for (const validator of registeredValidators[id] || []) {
				const newError = validator(currentValue, rowValues, id);
				if (newError) {
					return newError;
				}
			}

			return;
		},
		[registeredValidators]
	)

	const validateCallback = useCallback(
		(values: ActiveModule[]) => {
			const newValues = values || context.values[id] || [];
			const errors: ErrorsType[] = [];

			for (let i = 0; i < newValues.length; i++) {
				for (const fieldName of Object.keys(registeredValidators)) {
					const fieldError = validateFieldCallback(fieldName, newValues[i]);
					if (fieldError) {
						if (!errors[i]) {
							errors[i] = {};
						}

						errors[i][fieldName] = fieldError;
					}
				}
			}

			return errors.length === 0 ? undefined : errors;
		},
		[id, context.values, registeredValidators, validateFieldCallback]
	)

	// register validators in FormContext, so Form has access to it
	useEffect(
		() => {
			let validatorsToRegister: ValidatorFunctionType[] = []
			validator && validatorsToRegister.push(validator);
			validatorsToRegister.push(validateCallback);

			registerValidatorsInContext && registerValidatorsInContext(id, validatorsToRegister);
			return () => {
				registerValidatorsInContext && registerValidatorsInContext(id, []);
			}
		},
		[id, validator, registerValidatorsInContext, validateCallback]
	)

	const registerValidatorsCallback = useCallback(
		(index: number, id: string, validatorFunctions: Array<ValidatorFunctionType>) => {

			if (index !== 0) {
				return;
			}

			dispatchRegisteredValidators({
				type: ADD_VALIDATOR_TYPE,
				id,
				validatorFunctions
			})
		},
		[dispatchRegisteredValidators]
	);

	const rowHasErrorsCallback = useCallback(
		(rowErrors: any = {}) => {
			let haveError: boolean = false;
			Object.keys(rowErrors).map((key: string) => {
				if (key && rowErrors[key].length > 0) {
					haveError = true;
				}
				return null; // just for eslint
			});
			return haveError;
		},
		[]
	)

	const activeModulesHasErrorsCallback = useCallback(
		(activeModulesErrors: any[]) => {
			for (const rowErrors of activeModulesErrors) {
				if (rowErrors && rowHasErrorsCallback(rowErrors)) {
					return true;
				}
			}

			return false;
		},
		[rowHasErrorsCallback]
	)

	const onBlurCallback = useCallback(
		(index: number, fieldName: string, rowValues?: any) => {
			let errors: ErrorsType[] | undefined = (context.errorsMap[id] as ErrorsType[]) || [];
			const fieldError = validateFieldCallback(fieldName, rowValues || context.values[id][index]);
			if (fieldError) {
				if (!errors[index]) {
					errors[index] = {};
				}

				errors[index][fieldName] = fieldError;
			} else {
				if (errors[index]) {
					delete errors[index][fieldName];
				}

				const tableHasErrors = activeModulesHasErrorsCallback(errors);
				if (!tableHasErrors) {
					errors = undefined;
				}
			}

			context.setFieldError && context.setFieldError(id, errors);
		},
		[id, context, validateFieldCallback, activeModulesHasErrorsCallback]
	)

	const onChangeCallback = useCallback(
		(index: number, fieldName: string, newValue: any) => {
			const values = [...context.values[id]];
			values[index] = new ActiveModule({
				...values[index]
			});

			values[index][fieldName] = newValue;

			const moduleId = values[index].id

			// dependent modules
			if (moduleId === ModuleActivityEnum.Scope && !newValue) {
				values.forEach((v, i) => {
					if((v.id === ModuleActivityEnum.Testing || v.id === ModuleActivityEnum.Training) && v.isActive) {
						values[i][fieldName] = false;
					}
				})
			}

			if ((moduleId === ModuleActivityEnum.Testing || moduleId === ModuleActivityEnum.Training) && newValue) {
				values.forEach((v, i) => {
					if((v.id === ModuleActivityEnum.Scope) && !v.isActive) {
						values[i][fieldName] = true;
					}
				})
			}

			context.setFieldValue && context.setFieldValue(id, values);
			onBlurCallback(index, fieldName, values[index]);
		},
		[id, context, onBlurCallback]
	)

	return (
		<ProjectActiveModules
			value={context.values[id]}
			onChange={onChangeCallback}
			disabled={disabled || context.disabled}

			{...rest}

			errors={context.errorsMap[id] as ErrorsType[]}
			registerValidators={registerValidatorsCallback}
			validateField={onBlurCallback}
		/>
	)
}
