import Button from 'components/Button';
import { Form, InputNumberField, RadioField, SelectField } from 'components/Form';
import { SmartContainer, SmartInline, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { additionalFilterOptions, AndOrEnum, findAllFilters } from '../../filtersHelper';
import { NumberFilterModel } from './NumberFilterModel';
import { CurrencyColumnModel, DurationColumnModel, FilterType, NumberColumnModel } from 'components/Table';
import { convertMinutesToTicks, convertTicksToMinutes } from 'utils/dateTimeUtils';

type Props = {
	column: NumberColumnModel | CurrencyColumnModel | DurationColumnModel
	filters: FilterType[]
	onSave: (filters: FilterType[]) => void
	onCancel: () => void
}

const filterTypeOptions = [
	{ value: GenericFilterOperationEnum.EQUALS, text: 'i18n.label.filter.operation.equals' },
	{ value: GenericFilterOperationEnum.NOT_EQUALS, text: 'i18n.label.filter.operation.notEquals' },
	{ value: GenericFilterOperationEnum.GREATER_EQUAL_THAN, text: 'i18n.label.filter.operation.greaterEqualThan' },
	{ value: GenericFilterOperationEnum.GREATER_THAN, text: 'i18n.label.filter.operation.greaterThan' },
	{ value: GenericFilterOperationEnum.LESS_EQUAL_THAN, text: 'i18n.label.filter.operation.lessEqualThen' },
	{ value: GenericFilterOperationEnum.LESS_THAN, text: 'i18n.label.filter.operation.lessThan' },
]

export const NumberFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<NumberFilterModel>(new NumberFilterModel());

	const convertValueToTicks = useCallback(
		(value: number | undefined, column: NumberColumnModel | CurrencyColumnModel | DurationColumnModel) => {
			if (column instanceof DurationColumnModel) {
				return convertMinutesToTicks(value);
			}

			return value;
		},
		[]
	)

	const convertValueFromTicks = useCallback(
		(value: number | undefined, column: NumberColumnModel | CurrencyColumnModel | DurationColumnModel) => {
			if (column instanceof DurationColumnModel) {
				return convertTicksToMinutes(value);
			}

			return value;
		},
		[]
	)

	useEffect(
		() => {
			const newValues = new NumberFilterModel();

			const currentFilters = findAllFilters(filters, column.id);
			// if there are two filters that means AND filter is used
			if (currentFilters && currentFilters.length === 2) {
				newValues.additionalFilter = AndOrEnum.And;
				newValues.filterType1 = currentFilters[0].operation;
				newValues.filterValue1 = convertValueFromTicks(currentFilters[0].value, column);
				newValues.filterType2 = currentFilters[1].operation;
				newValues.filterValue2 = convertValueFromTicks(currentFilters[1].value, column);
				// if there is one filter, but without OR filters
			} else if (currentFilters && currentFilters.length === 1 && (!currentFilters[0].orFilters || currentFilters[0].orFilters.length === 0)) {
				newValues.additionalFilter = AndOrEnum.And;
				newValues.filterType1 = currentFilters[0].operation;
				newValues.filterValue1 = convertValueFromTicks(currentFilters[0].value, column);
				// if there is one filter, with two OR filters
			} else if (currentFilters && currentFilters.length === 1 && currentFilters[0].orFilters && currentFilters[0].orFilters.length === 2) {
				newValues.additionalFilter = AndOrEnum.Or;
				newValues.filterType1 = currentFilters[0].orFilters[0].operation
				newValues.filterValue1 = convertValueFromTicks(currentFilters[0].orFilters[0].value, column)
				newValues.filterType2 = currentFilters[0].orFilters[1].operation
				newValues.filterValue2 = convertValueFromTicks(currentFilters[0].orFilters[1].value, column)
				// if there is one filter, with one OR filters
			} else if (currentFilters && currentFilters.length === 1 && currentFilters[0].orFilters && currentFilters[0].orFilters.length === 1) {
				newValues.additionalFilter = AndOrEnum.Or;
				newValues.filterType1 = currentFilters[0].orFilters[0].operation
				newValues.filterValue1 = convertValueFromTicks(currentFilters[0].orFilters[0].value, column)
			}

			setValues(newValues);
		},
		[filters, column, convertValueFromTicks]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];
			// remove current filters with specific columnId
			const currentFilters = findAllFilters(newFilters, column.id);
			if (currentFilters.length > 0) {
				currentFilters.forEach(filter => {
					const index = newFilters.indexOf(filter);
					newFilters.splice(index, 1);
				})
			}

			const newFilter: FilterType = {}
			// If OR filter is selected, add filters to orFilters array
			if (values.additionalFilter === AndOrEnum.Or) {
				newFilter.orFilters = []
				if (values.filterType1) {
					newFilter.orFilters.push({
						columnId: column.id,
						operation: values.filterType1,
						value: convertValueToTicks(values.filterValue1, column)
					})
				}

				if (values.filterType2) {
					newFilter.orFilters.push({
						columnId: column.id,
						operation: values.filterType2,
						value: convertValueToTicks(values.filterValue2, column)
					})
				}
				if (newFilter.orFilters.length > 0) {
					newFilters.push(newFilter);
				}
			}
			// If AND filter is selected add "Parent" filter
			if (values.additionalFilter === AndOrEnum.And) {
				if (values.filterType1) {
					newFilters.push({
						columnId: column.id,
						operation: values.filterType1,
						value: convertValueToTicks(values.filterValue1, column)
					})
				}

				// If second filter is selected add new "Parent" filter
				if (values.filterType2) {
					newFilters.push({
						columnId: column.id,
						operation: values.filterType2,
						value: convertValueToTicks(values.filterValue2, column)
					})
				}
			}

			onSave && await onSave(newFilters);
		},
		[filters, column, onSave, values, convertValueToTicks]
	)

	const clearCallback = useCallback(
		() => {
			setValues(new NumberFilterModel());
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='i18n.button.apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SmartInline>
							<SelectField
								id='filterType1'
								items={filterTypeOptions}
								getItemId={(item) => item.value}
								getItemText={(item) => item.text}
								inline
							/>
							<InputNumberField
								id='filterValue1'
								inline
							/>
						</SmartInline>
						<RadioField
							id='additionalFilter'
							items={additionalFilterOptions}
						/>
						<SmartInline>
							<SelectField
								id='filterType2'
								items={filterTypeOptions}
								getItemId={(item) => item.value}
								getItemText={(item) => item.text}
								inline
							/>
							<InputNumberField
								id='filterValue2'
								inline
							/>
						</SmartInline>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='i18n.button.clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}
