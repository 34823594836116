import { LockIcon } from 'components/icons/icons';
import styles from './styles.module.scss'
import { Translation } from 'components/Translations/Translation';

type Props = {
	content: string
}

export const FrozenElement = ({ content }: Props) => {
	return (
		<div className={styles.frozen_element}>
			<Translation i18n={content} />
			<LockIcon className={styles.icon} width={11} height={11} fill='currentColor' />
		</div>
	);
};
