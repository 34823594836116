import { useCallback, useState } from 'react'
import styles from './semaphore.module.scss'
import { defaultTooltipState } from 'components/Table/CustomTable/Body/Cell'

export type SemaphoreItemType = {
	id: string | number | boolean
	color: string
	text?: string
	explanation?: string
}

type Props = SemaphoreItemType & {
	isSelected?: boolean
	onClick?(id?: string | number | boolean): void
	disabled?: boolean
}

export const SemaphoreItem = ({ id, color, text, isSelected, onClick, disabled }: Props) => {
	const [tooltipState, setTooltipState] = useState(defaultTooltipState);

	const handleItemChange = useCallback(
		() => {
			onClick && onClick(isSelected ? undefined : id);
		},
		[id, isSelected, onClick]
	)

	const handleMouseOver = useCallback(
		(e: React.MouseEvent<HTMLDivElement>) => {
			const { top, left, width, height } = e.currentTarget.getBoundingClientRect();
			const tooltipY = top - height - 12;
			const tooltipX = left + width / 2;

			setTooltipState({
				visible: true,
				position: { x: tooltipX, y: tooltipY }
			});
		},
		[]
	)

	const handleMouseLeave = useCallback(
		() => setTooltipState(defaultTooltipState),
		[]
	)

	const className = `${styles.semaphore_item} ${disabled ? styles.disabled : ''} ${isSelected ? styles.selected : ''}`

	return (
		<div
			onClick={handleItemChange}
			className={className}
			style={{
				background: isSelected ? color.toLowerCase() : undefined
			}}
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}
		>
			{tooltipState.visible && text &&
				<div
					className={styles.tooltip}
					style={{
						top: `${tooltipState.position.y}px`,
						left: `${tooltipState.position.x}px`
					}}
				>
					{text}
					<div className={styles.arrow}></div>
				</div>
			}
		</div>
	);
}
