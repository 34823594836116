import { ValidatorFunctionType } from 'components/Form/models';
import { useReducer } from 'react';

export const ADD_VALIDATOR_TYPE = 'ADD_VALIDATOR';

type ActionType = {
	type: string
	index: number
	id: string
	validatorFunctions: ValidatorFunctionType[]
}

type StateType = Array<{
	[fieldName: string]: ValidatorFunctionType[]
}>

const initialState: StateType = [];

function registeredValidatorsReducer(state: StateType, action: ActionType): StateType {
	switch (action.type) {
		case ADD_VALIDATOR_TYPE:
			const oldItemValidators = state[action.index] || {};
			const newItemValidators = {
				...oldItemValidators,
				[action.id]: action.validatorFunctions
			}

			const newState = [...state];
			newState[action.index] = newItemValidators;

			return newState;
		default:
			return state;
	}
}

export const useItemsRegisteredValidatorsReducer = (): [StateType, React.Dispatch<ActionType>] => {
	return useReducer(registeredValidatorsReducer, initialState);
}
