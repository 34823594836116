type EnvSettings = {
	stage: 'local' | 'staging' | 'production'
	type: 'application' | 'registration'
	apiUrl: string
	alertsApiUrl: string
	siteDomain: string
	myTestDomain: string
	showLanguageControl: boolean
	isPrivateCloudTenant: boolean
}

// for localhost it is set inside of .env
// and for all others inside of pipeline .yml file
export const envSettings: EnvSettings = {
	stage: process.env.REACT_APP_STAGE as any,
	type: process.env.REACT_APP_TYPE as any,

	apiUrl: process.env.REACT_APP_API_URL || '',
	alertsApiUrl: process.env.REACT_APP_ALERTS_API_URL || '',
	siteDomain: process.env.REACT_APP_SITE_DOMAIN || '',

	// only for localhost
	myTestDomain: process.env.REACT_APP_MY_TEST_DOMAIN || '',
	showLanguageControl: process.env.REACT_APP_SHOW_LANGUAGE_CONTROL === "true",

	// for now only for eps
	isPrivateCloudTenant: process.env.REACT_APP_IS_PRIVATE_CLOUD_TENANT === "true"
}

console.log('env:', envSettings);
