import { ArrowDownIcon } from 'components/icons/icons';
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ControlsCommonProps } from '../../fields';
import styles from './multiSelect.module.scss';
import Spinner, { ClipSpinner } from 'components/Spinner';
import { Option } from './Option'
import { Input } from "../Input/Input"
import { Cover } from 'components/Cover';
import { Link } from 'react-router-dom';
import { createPortal } from 'react-dom';
import { useGetTranslation } from 'components/Translations/translationUtils';
import { Translation } from 'components/Translations/Translation';

const selectAllKey = 'select_all'

const multiselectId = 'multiselectId'

// Multiselect (like Textarea) accepts Enter
// so this method is used in Form to prevent auto save of the form via Enter key
export const isMultiSelectHit = (target: HTMLElement) => {
	return !!target.closest(`#${multiselectId}`);
}

export type MultiSelectProps = ControlsCommonProps<Array<string | number>> & {
	items?: any[]
	getItemId(item: any): string | number
	getItemText(item: any): string
	loading?: boolean
	sort?: boolean
	hasSelectAll?: boolean
	numOfItemNames?: number
	isLink?: boolean
	getItemLinkRoute?(item: any): string
	size?: 'medium' | 'small'
	focus?: boolean
	usePortal?: boolean
};

export const MultiSelect = (props: MultiSelectProps) => {
	const {
		value, onChange, onBlur, disabled,
		items = [], getItemId, getItemText, getItemLinkRoute,
		loading, sort, isLink, hasSelectAll = true, numOfItemNames = 1,
		size = 'medium', focus = false, usePortal = true
	} = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const [expanded, setExpanded] = useState(focus);
	const [searchValue, setSearchValue] = useState<string>();
	const [keyboardItem, setKeyboardItem] = useState<any | undefined>();
	const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});

	const getTranslation = useGetTranslation();

	const filteredItemsMemo = useMemo(
		() => {
			// filter by searchValue
			let newFilteredItems = items.filter((item) => {
				const text = getTranslation(getItemText(item));
				return text?.toLowerCase().includes(searchValue?.toLowerCase() || '');
			})
			// sort by alphabet
			if (sort) {
				newFilteredItems = newFilteredItems.sort((a, b) => {
					const textA = getTranslation(getItemText(a))?.toUpperCase() || '';
					const textB = getTranslation(getItemText(b))?.toUpperCase() || ''
					if (textA < textB) {
						return -1;
					}
					if (textA > textB) {
						return 1;
					}
					return 0;
				})
			}
			return newFilteredItems;
		},
		[items, sort, getItemText, searchValue, getTranslation]
	)

	const onChangeCallback = useCallback(
		(item: any | undefined) => {
			let id = getItemId(item)
			const values = value || []
			let newValues;

			if (values.includes(id)) {
				newValues = values.filter(x => x !== id);
			} else {
				newValues = [...values, id]
			}

			onChange && onChange(newValues);
		},
		[getItemId, onChange, value]
	)

	const isAllSelectedMemo = useMemo(
		() => filteredItemsMemo.every(x => value?.includes(getItemId(x))),
		[filteredItemsMemo, value, getItemId]
	)

	const selectAllCallback = useCallback(
		() => {
			const values = value || [];
			const filteredItemIds = filteredItemsMemo.map(x => getItemId(x));

			// remove filtered items
			let newValues = values.filter(x => !filteredItemIds.includes(x));

			// if filtered items are NOT already included, add all of them
			// otherwise, they are already removed
			if (!isAllSelectedMemo) {
				newValues = [...newValues, ...filteredItemIds]
			}
			onChange && onChange(newValues);
		},
		[getItemId, onChange, value, isAllSelectedMemo, filteredItemsMemo]
	)

	const onBlurCallback = useCallback(
		() => {
			onBlur && onBlur();
			setSearchValue(undefined);
			setExpanded(false);
			setKeyboardItem(undefined);
		},
		[onBlur]
	)

	const onKeyDownCallback = useCallback(
		(eventKey: string) => {
			if (!expanded) {
				return;
			}

			switch (eventKey) {
				case 'Enter':
					if (keyboardItem) {
						onChangeCallback(keyboardItem);
					}
					break;
				case 'ArrowUp':
					setKeyboardItem((state) => {
						const index = filteredItemsMemo.indexOf(state);

						if (index === -1) {
							return filteredItemsMemo.at(-1);
						} else {
							return filteredItemsMemo.at(index - 1);
						}
					})
					break;
				case 'ArrowDown':
					setKeyboardItem((state) => {
						const index = filteredItemsMemo.indexOf(state);

						if (index === filteredItemsMemo.length - 1) {
							return filteredItemsMemo.at(0);
						} else {
							return filteredItemsMemo.at(index + 1);
						}
					})
					break;
			}
		},
		[filteredItemsMemo, keyboardItem, onChangeCallback, expanded]
	)

	const optionsContent = useMemo(
		() => {
			return filteredItemsMemo.map((item) => {
				const id = getItemId(item);

				return (
					<Option
						key={getItemId(item)}
						item={item}
						getItemText={getItemText}
						onClick={onChangeCallback}
						isSelected={value?.includes(id)}
						isKeyboardItem={keyboardItem === item}
					/>
				)
			})
		},
		[filteredItemsMemo, getItemId, getItemText, onChangeCallback, value, keyboardItem]
	)

	const dropdownContentMemo = useMemo(
		() => {
			const dropdownContent = (
				<div className={`${styles.dropdown} ${!usePortal ? styles.not_portal : ''} ${styles.open}`} style={dropdownStyle}>
					<Input
						value={searchValue}
						onChange={setSearchValue}
						onKeyDown={onKeyDownCallback}
						placeholder='i18n.label.search'
						disabled={disabled || loading}
						hideMaxLength
						focus
					/>
					{hasSelectAll && filteredItemsMemo.length > 0 && (
						<Option
							key={selectAllKey}
							item={null}
							getItemText={() => searchValue ? 'i18n.label.selectAllFiltered' : 'i18n.label.selectAll'}
							onClick={selectAllCallback}
							isSelected={isAllSelectedMemo}
						/>
					)}
					{optionsContent}
					{optionsContent.length === 0 && (
						<div className={styles.no_options}>
							<Translation i18n='i18n.label.noOptions' />
						</div>
					)}
				</div>
			);

			if (usePortal) {
				return createPortal(dropdownContent, document.body);
			}

			return dropdownContent;
		},
		[disabled, dropdownStyle, filteredItemsMemo.length, hasSelectAll, isAllSelectedMemo, loading, onKeyDownCallback, optionsContent, searchValue, selectAllCallback, usePortal]
	)

	const selectedContent = useMemo(
		() => {
			if (!value || value.length === 0) {
				return '';
			}

			if (value.length === items.length) {
				return <Translation i18n='i18n.label.allItemsSelected' />
			}

			if (value.length > numOfItemNames) {
				return <Translation i18n='i18n.label.selectedItems' args={[value.length.toString()]}/>
			}

			if (items.length === 0) {
				return '';
			}

			const selectedItems = value.map(x => items.find(item => getItemId(item) === x))

			return selectedItems.map((x, i) => (
				<span key={getItemId(x)}>
					{i > 0 && ", "}
					<div className={styles.inline_block} key={getItemId(x)}>
						{isLink && getItemLinkRoute ? (
							<Link className={styles.link} to={getItemLinkRoute(x)} target='_blank'>
								{getTranslation(getItemText(x))}
							</Link>
						) : getTranslation(getItemText(x))}
					</div>
				</span>
			))
		},
		[value, items, numOfItemNames, getItemId, isLink, getItemLinkRoute, getTranslation, getItemText]
	)

	const calculateDropdownPortalStyle = useCallback(
		() => {
			const container = containerRef.current;

			if (container) {
				const { bottom, top, width, left } = container.getBoundingClientRect();

				const viewportHeight = window.innerHeight;
				const spaceBelow = viewportHeight - bottom;
				const spaceAbove = top
				const dropdownHeight = optionsContent.length >= 8 ? 256 : optionsContent.length > 0 ? optionsContent.length * 32 : 32; // maxHeight(8 options * 32px)
				const openAbove = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;
				const dropdownMaxWidth = 500;

				const dropdownStyle: React.CSSProperties = {
					minWidth: `${width}px`,
					maxWidth: `${dropdownMaxWidth}px`,
					left: `${left + window.scrollX}px`,
					...(openAbove ?
						{ bottom: `${viewportHeight - spaceAbove - window.scrollY}px` } :
						{ top: `${bottom + window.scrollY}px` }
					),
				};

				setDropdownStyle(dropdownStyle);
			}
		},
		[optionsContent]
	)

	useLayoutEffect(
		() => {
			if (usePortal && expanded) {
				calculateDropdownPortalStyle();

				window.addEventListener('scroll', calculateDropdownPortalStyle, true);

				return () => {
					window.removeEventListener('scroll', calculateDropdownPortalStyle, true);
				}
			}
		},
		[usePortal, expanded, calculateDropdownPortalStyle]
	)

	const expandCallback = useCallback(
		() => {
			if (!disabled && !loading) {
				// Check calculation before set expanded, so that dropdown knows whether to open above or below
				calculateDropdownPortalStyle();
				setExpanded(true)
			}
		},
		[disabled, loading, calculateDropdownPortalStyle]
	)

	return (
		<div ref={containerRef} id={multiselectId} className={`${styles.container} ${size === 'small' ? styles.small : ''}`}>
			{expanded && <Cover onClick={onBlurCallback} transparent />}
			<div className={styles.select_container} onClick={expandCallback}>
				<div className={`${styles.select} ${size === 'small' ? styles.small : ''} ${expanded ? styles.focus : ''} ${disabled ? styles.disabled : ''}`}>
					{selectedContent}
				</div>
				{/* arrow */}
				<div className={`${styles.arrow} ${size === 'small' ? styles.small : ''}`}>
					<ArrowDownIcon width={8} height={8} fill='currentColor' />
				</div>
				{/* dropdown */}
				{expanded && dropdownContentMemo}
				{/* loading */}
				{loading &&
					<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
						<Spinner>
							<ClipSpinner size={20} />
						</Spinner>
					</div>
				}
			</div>
		</div>
	)
}
