import { ControlsCommonProps } from "components/Form/fields"
import { ClipSpinner } from "components/Spinner"
import { ArrowDownIcon } from "components/icons/icons"
import styles from './autoComplete.module.scss'
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { Input } from "../Input/Input"
import { Option } from "./Option"
import { emptyArray, EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import Spinner from "components/Spinner/Spinner"
import { createPortal } from "react-dom"
import { GenericFilterModel, GenericFilterModelCollection, IdNameResponse, IdNameResponseItemsResponseModel, IdNameResponseSimpleResponseModel } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { Translation } from 'components/Translations/Translation'

export const autoCompleteRemoteId = 'autoCompleteRemoteId';

const LIMIT = 6;
const SEARCH_DEBOUNCE = 1000;

type ItemsModel = {
	items: IdNameResponse[],
	count?: number
}

const emptyItemsModel: ItemsModel = {
	items: emptyArray,
	count: undefined
}

// AutoComplete (like Textarea) accepts Enter
// so this method is used in Form to prevent auto save of the form via Enter key
export const isAutoCompleteRemoteHit = (target: HTMLElement) => {
	return !!target.closest(`#${autoCompleteRemoteId}`);
}

export type AutoCompleteRemoteProps = ControlsCommonProps<number | undefined> & {
	entityPrefix: EntityPrefixEnum | undefined
	filters?: GenericFilterModel[] | undefined;
	fetchItemsFunction(genericFilter: GenericFilterModelCollection, searchValue: string | undefined): Promise<IdNameResponseItemsResponseModel>
	fetchSingleItemFunction(id: number): Promise<IdNameResponseSimpleResponseModel>
	getItemText?(item: IdNameResponse): string | undefined
	getItemStyle?: (item: IdNameResponse) => React.CSSProperties
	/** If there is a need for different display in Input and Option */
	getText?(item: IdNameResponse): string | undefined
	placeholder?: string
	focus?: boolean
	size?: 'medium' | 'small'
	usePortal?: boolean
}

export const AutoCompleteRemote = (props: AutoCompleteRemoteProps) => {
	const {
		value, onChange, onBlur, disabled,
		entityPrefix,
		filters, fetchItemsFunction, fetchSingleItemFunction,
		getItemText, getItemStyle, getText,
		placeholder, size = 'medium',
		focus = false, usePortal = true,
	} = props;

	const containerRef = useRef<HTMLDivElement>(null);

	const [expanded, setExpanded] = useState(false);
	const [searchValue, setSearchValue] = useState<string>();
	const [isFetchingSearchItems, setIsFetchingSearchItems] = useState<boolean>(false);
	const [filteredItems, setFilteredItems] = useState<ItemsModel>(emptyItemsModel);

	const [offset, setOffset] = useState(0)
	const [keyboardItem, setKeyboardItem] = useState<any | undefined>();
	const [dropdownStyle, setDropdownStyle] = useState<React.CSSProperties>({});

	const [selectedItem, setSelectedItem] = useState<IdNameResponse | undefined>(undefined)
	const [isFetchingValue, setIsFetchingValue] = useState<boolean>(false);

	const lastItemRef = useRef(null);

	useEffect(() => {
		// if we fetched all items, or fetching is in progress, skip
		if (filteredItems.items.length === filteredItems.count || isFetchingSearchItems)  {
			return;
		}

		const callback = (entries) => {
			// if scrolled to the last item, update offset and trigger new fetch
			if (entries[0].isIntersecting && filteredItems.count && offset + LIMIT < filteredItems.count) {
				setOffset((prev) => prev + LIMIT);
			}
		};

		const observerInstance = new IntersectionObserver(callback, {
			root: null,
			threshold: 1.0,
		});

		if (lastItemRef.current) {
			observerInstance.observe(lastItemRef.current);
		}

		return () => {
			if (lastItemRef.current) {
				// eslint-disable-next-line react-hooks/exhaustive-deps
				observerInstance.unobserve(lastItemRef.current);
			}
		};
	  }, [filteredItems, isFetchingSearchItems, offset]);

	useEffect(
		() => {
			if (focus) {
				setExpanded(true);
			}
		},
		[focus]
	)

	const fetchFilteredItemsCallback = useCallback(
		async () => {
			const bindedAction = fetchItemsFunction.bind(null, new GenericFilterModelCollection({filters, offset: offset, limit: LIMIT}), searchValue);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				let items = response.items || emptyArray;

				setFilteredItems((state => {
					return {
						items: offset > 0 ? [...state.items, ...items] : items,
						count: response.count
					}
				}))
			}
			setIsFetchingSearchItems(false)
		},
		[searchValue, filters, fetchItemsFunction, offset]
	)

	useEffect(
		() => {
			// set fetching, while user is typing
			setIsFetchingSearchItems(true)

			// if autocomplete is just focused or user is scrolling, fetch immediately
			if (!searchValue || offset > 0) {
				expanded && fetchFilteredItemsCallback()
			} else {
				const handler = setTimeout(() => {
					expanded && fetchFilteredItemsCallback()
				  }, SEARCH_DEBOUNCE);

				  return () => {
					clearTimeout(handler);
				  };
			}
		},
		[fetchFilteredItemsCallback, expanded, searchValue, offset]
	)

	const fetchSelectedItemDataCallback = useCallback(
		async (id: number) => {
			setIsFetchingValue(true)

			const bindedAction = fetchSingleItemFunction.bind(null, id);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				setSelectedItem(response.value)
			}
			setIsFetchingValue(false)
		},
		[fetchSingleItemFunction]
	)

	useEffect(
		() => {
			if (value) {
				value !== selectedItem?.id && fetchSelectedItemDataCallback(Number(value))
			} else {
				setSelectedItem(undefined)
			}
		},
		[fetchSelectedItemDataCallback, value, selectedItem]
	)

	const onChangeCallback = useCallback(
		(item: IdNameResponse | undefined) => {
			let id: number | undefined = item?.id;

			if (id !== value) {
				onChange && onChange(id);
			}
			setSelectedItem(item)
			setSearchValue(undefined);
			setFilteredItems(emptyItemsModel)
			setOffset(0);
			setExpanded(false);
			setKeyboardItem(undefined);
		},
		[onChange, value]
	)

	const onBlurCallback = useCallback(
		() => {
			if (searchValue === '') {
				onChangeCallback(undefined)
			}
			onBlur && onBlur();
			setSearchValue(undefined);
			setFilteredItems(emptyItemsModel)
			setOffset(0);
			setExpanded(false);
			setKeyboardItem(undefined);
		},
		[onBlur, onChangeCallback, searchValue]
	)

	const expandCallback = useCallback(
		() => !disabled && setExpanded(true),
		[disabled]
	)

	const onKeyDownCallback = useCallback(
		(eventKey: string) => {
			if (!expanded) {
				return;
			}

			switch (eventKey) {
				case 'Enter':
					if (keyboardItem) {
						onChangeCallback(keyboardItem);
					} else if (searchValue === '') {
						onChangeCallback(undefined);
					}
					break;
				case 'ArrowUp':
					setKeyboardItem((state) => {
						const index = filteredItems.items.indexOf(state);

						if (index === -1) {
							return filteredItems.items.at(-1);
						} else {
							return filteredItems.items.at(index - 1);
						}
					})
					break;
				case 'ArrowDown':
					setKeyboardItem((state) => {
						const index = filteredItems.items.indexOf(state);

						if (index === filteredItems.items.length - 1) {
							return filteredItems.items.at(0);
						} else {
							return filteredItems.items.at(index + 1);
						}
					})
					break;
			}
		},
		[searchValue, filteredItems, keyboardItem, onChangeCallback, expanded]
	)

	const getItemTextMemo = useMemo(
		() => {
			// if getItemText is provided use it
			if (getItemText) {
				return getItemText;
			}

			// if entity prefix is provided, create default getItemText
			if (entityPrefix) {
				return (item: IdNameResponse) => {
					return `${getFormatedId(entityPrefix, item.id)} - ${item.name}`
				}
			}

			// otherwise, return just name
			return (item: IdNameResponse) => {
				return item.name
			}
		},
		[entityPrefix, getItemText]
	)

	const optionsContent = useMemo(
		() => {
			return filteredItems.items.map((item, index) => {
				const id = item.id;

				return (
					<Option
						key={item.id}
						item={item}
						getItemText={getItemTextMemo}
						onClick={onChangeCallback}
						isSelected={value === id || keyboardItem === item}
						getItemStyle={getItemStyle}
						ref={index === filteredItems.items.length - 1 ? lastItemRef : undefined}
					/>
				)
			})
		},
		[filteredItems, onChangeCallback, getItemStyle, value, keyboardItem, getItemTextMemo]
	)

	const selectedItemStyle = useMemo(
		() => {
			if (selectedItem) {
				return getItemStyle?.(selectedItem);
			}
		},
		[getItemStyle, selectedItem]
	)

	const selectedItemText = selectedItem ? (getText?.(selectedItem) || getItemTextMemo(selectedItem)) : undefined;

	const dropdownContentMemo = useMemo(
		() => {
			const dropdownContent = (
				<div className={`${styles.dropdown} ${!usePortal ? styles.not_portal : ''} ${expanded ? styles.open : ''}`} style={dropdownStyle}>
					{optionsContent}
					{/* loading */}
					{isFetchingSearchItems &&
						<div className={styles.option_spinner}>
							<Spinner>
								<ClipSpinner size={20} />
							</Spinner>
						</div>
					}
					{optionsContent.length === 0 && !isFetchingSearchItems && (
						<div className={styles.no_options}>
							<Translation i18n='i18n.label.noOptions' />
						</div>
					)}
				</div>
			);

			if (usePortal) {
				return createPortal(dropdownContent, document.body);
			}

			return dropdownContent;
		},
		[expanded, optionsContent, usePortal, dropdownStyle, isFetchingSearchItems]

	)

	const calculateDropdownPortalStyle = useCallback(
		() => {
			const container = containerRef.current;

			if (container) {
				const { bottom, top, width, left } = container.getBoundingClientRect();

				const viewportHeight = window.innerHeight;
				const spaceBelow = viewportHeight - bottom;
				const spaceAbove = top
				const dropdownHeight = optionsContent.length >= 5 ? 160 : optionsContent.length > 0 ? optionsContent.length * 32 : 32; // maxHeight(5 options * 32px)
				const openAbove = spaceBelow < dropdownHeight && spaceAbove > spaceBelow;
				const dropdownMaxWidth = 500;

				const dropdownStyle: React.CSSProperties = {
					minWidth: `${width}px`,
					maxWidth: `${dropdownMaxWidth}px`,
					left: `${left + window.scrollX}px`,
					...(openAbove ?
						{ bottom: `${viewportHeight - spaceAbove - window.scrollY}px` } :
						{ top: `${bottom + window.scrollY}px` }
					),
				};

				setDropdownStyle(dropdownStyle);
			}
		},
		[optionsContent]
	)

	useLayoutEffect(
		() => {
			if (usePortal && expanded) {
				calculateDropdownPortalStyle();

				window.addEventListener('scroll', calculateDropdownPortalStyle, true);

				return () => {
					window.removeEventListener('scroll', calculateDropdownPortalStyle, true);
				}
			}
		},
		[usePortal, expanded, calculateDropdownPortalStyle]
	)

	const searchValueChangeCallback = useCallback(
		(value: string | undefined) => {
			setSearchValue(value);
			setFilteredItems(emptyItemsModel);
			setOffset(0);
		},
		[]
	)

	return (
		<div ref={containerRef} id={autoCompleteRemoteId} className={`${styles.container} ${size === 'small' ? styles.small : ''}`}>
			<div className={styles.select_container} onClick={expandCallback}>
				<Input
					value={searchValue !== undefined ? searchValue : selectedItemText}
					onChange={searchValueChangeCallback}
					onBlur={onBlurCallback}
					onFocus={expandCallback}
					onKeyDown={onKeyDownCallback}
					placeholder={placeholder}
					disabled={disabled}
					hideMaxLength
					selectAllTextOnFocus
					size={size}
					focus={focus}
					style={selectedItemStyle}
				/>
				{/* dropdown */}
				{expanded && dropdownContentMemo}
				{/* arrow */}
				<div className={`${styles.arrow} ${size === 'small' ? styles.small : ''}`}>
					<ArrowDownIcon width={8} height={8} fill='currentColor' />
				</div>
				{/* loading */}
				{isFetchingValue &&
					<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
						<Spinner>
							<ClipSpinner size={20} />
						</Spinner>
					</div>
				}
			</div>
		</div>
	)
}
