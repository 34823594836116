import { RootState } from 'base/reducer/reducer';
import { OptionType } from 'components/Form';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { MonthEnum, WeekDayEnum } from 'services/tenantManagementService';
import { MeridiemTimeEnum } from 'utils/MeridiemTimeEnum';

export const getTimeDifferenceBetweenDates = (date1: Date, date2: Date) => {
	const differenceInTime = date2.getTime() - date1.getTime();
	return differenceInTime;
}

export const getNumberOfDaysBetweenDates = (date1: Date, date2: Date) => {
	const date1WithoutTIme = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const date2WithoutTIme = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1WithoutTIme, date2WithoutTIme) / (1000 * 3600 * 24)
	)
}

export const getNumberOfHoursBetweenDates = (date1: Date, date2: Date) => {
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1, date2) / (1000 * 3600)
	);
};

export const getNumberOfMinutesBetweenDates = (date1: Date, date2: Date) => {
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1, date2) / (1000 * 60)
	)
}

export const getNumberOfWeeksBetweenDates = (date1: Date, date2: Date) => {
	const date1WithoutTIme = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
	const date2WithoutTIme = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
	return Math.ceil(
		getTimeDifferenceBetweenDates(date1WithoutTIme, date2WithoutTIme) / (1000 * 3600 * 24 * 7)
	);
};

export const getNumberOfMonthsBetweenDates = (date1: Date, date2: Date) => {
	const differenceMonth = date2.getMonth() - date1.getMonth();
	const differenceYear = date2.getFullYear() - date1.getFullYear();
	return differenceMonth + differenceYear * 12;
};

export const getDateIncrementByNumberOfDays = (date: Date, days: number) => {
	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + days * 24 * 3600 * 1000);
	return dateWithTime;
};

export const getDateIncrementByNumberOfMonths = (date: Date, value: number) => {
	const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	dateWithoutTime.setMonth(dateWithoutTime.getMonth() + value);
	return dateWithoutTime;
};

export const getDateIncrementByNumberOfWeeks = (date: Date, value: number) => {
	const dateWithoutTime = new Date(date.getFullYear(), date.getMonth(), date.getDate());
	dateWithoutTime.setDate(dateWithoutTime.getDate() + 7 * value);
	return dateWithoutTime;
};

export const getDateIncrementByNumberOfHours = (date: Date, hours: number) => {
	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + hours * 3600 * 1000);
	return dateWithTime;
};

export const getDateIncrementByNumberOfMinutes = (date: Date, minutes: number) => {
	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + minutes * 60 * 1000);
	return dateWithTime;
};

export const getDateIncrementByNumberOfHoursBasedOnCalendarSettingsWorkTime = (date: Date, value: number, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfDays = Math.floor(value / workTime);
	const newValue = value + (differenceWorkTime * numberOfDays);

	const dateWithTime = new Date(date.getTime());
	dateWithTime.setTime(dateWithTime.getTime() + (newValue * 3600 * 1000));
	return dateWithTime;
};

export const getNumberOfHoursBetweenDatesBasedOnCalendarSettingsWorkTime = (date1: Date, date2: Date, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfDays = getNumberOfDaysBetweenDates(date1, date2);
	return (
		Math.ceil(getTimeDifferenceBetweenDates(date1, date2) / (1000 * 3600)) - (differenceWorkTime * numberOfDays)
	);
};

export const getNumberOfDaysBetweenDatesWithoutWeekends = (date1: Date, date2: Date) => {
	const startDate = new Date(date1);
	let workingDays = 0;

	while (startDate < date2) {
		const day = startDate.getDay();
		if (day !== 0 && day !== 6) {
			workingDays++;
		}
		startDate.setDate(startDate.getDate() + 1);
	}
	return workingDays;
};

export const getDateIncrementByNumberOfDaysWithoutWeekends = (date: Date, value: number) => {
	const newDate = new Date(date);
	let workingDays = value;

	while (workingDays > 0) {
		const day = newDate.getDay();
		if (day !== 0 && day !== 6) {
			workingDays--;
		}
		newDate.setDate(newDate.getDate() + 1);
	}

	return newDate;
};

export const getNumberOfHoursBetweenDatesWithoutWeekends = (date1: Date, date2: Date) => {
	const startDate = new Date(date1);
	let workingHours = 0;

	while (startDate < date2) {
		const day = startDate.getDay();
		if (day !== 0 && day !== 6) {
			workingHours++;
		}
		startDate.setTime(startDate.getTime() + (3600 * 1000));
	}
	return workingHours;
};

export const getDateIncrementByNumberOfHoursWithoutWeekends = (date: Date, value: number) => {
	const newDate = new Date(date.getTime());
	let workingHours = value;

	while (workingHours > 0) {
		const day = newDate.getDay();
		if (day !== 0 && day !== 6) {
			workingHours--;
		}
		newDate.setTime(newDate.getTime() + (3600 * 1000));
	}

	return newDate;
};

export const getNumberOfHoursBetweenDatesWithoutWeekendsBasedOnCalendarSettingsWorkTime = (date1: Date, date2: Date, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfHours = getNumberOfHoursBetweenDatesWithoutWeekends(date1, date2);
	const numberOfDays = Math.floor(numberOfHours / 24);
	return getNumberOfHoursBetweenDatesWithoutWeekends(date1, date2) - differenceWorkTime * numberOfDays;
};

export const getDateIncrementByNumberOfHoursWithoutWeekendsBasedOnCalendarSettingsWorkTime = (date: Date, value: number, workTime: number) => {
	const differenceWorkTime = 24 - workTime;
	const numberOfDays = Math.floor(value / workTime);
	const newValue = value + (differenceWorkTime * numberOfDays);
	return getDateIncrementByNumberOfHoursWithoutWeekends(date, newValue);
};

export const formatDateByPattern = (date: Date | undefined, pattern: string) => {
	if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
		return '';
	}
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();

	const map = {
		d: day,                               	// Day (1-31)
		dd: String(day).padStart(2, '0'),    	// Day (2 digits)
		M: month,                         		// Month (1-12)
		MM: String(month).padStart(2, '0'), 	// Month (2 digits)
		yyyy: year,                       		// Year (4 digits)
		yy: String(year).slice(-2),       		// Year (2 digits)
	};

	// Replace each token in the format string
	return pattern.replace(/d{1,2}|M{1,2}|yyyy|yy/g, (match) => map[match]);
}

export const formatTimeByPattern = (date: Date | undefined, pattern: string): string => {
	if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
		return '';
	}

	const hours24 = date.getHours(); // 0-23
	const hours12 = hours24 % 12 || 12; // 1-12
	const minutes = date.getMinutes();
	const amPm = hours24 < 12 ? 'AM' : 'PM';

	const map = {
		H: hours24,                                	// Hour (0-23)
		HH: String(hours24).padStart(2, '0'),     	// Hour (00-23)
		h: hours12,                                	// Hour (1-12)
		hh: String(hours12).padStart(2, '0'),     	// Hour (01-12)
		m: minutes,                                	// Minute (0-59)
		mm: String(minutes).padStart(2, '0'),     	// Minute (00-59)
		tt: amPm,                                  	// AM/PM
	};

	// Replace each token in the format string
	return pattern.replace(/H{1,2}|h{1,2}|m{1,2}|tt/g, (match) => map[match]);
};

export const formatDateTimeByPattern = (date: Date | undefined, pattern: string): string => {
	if (!date || !(date instanceof Date) || isNaN(date.getTime())) {
		return '';
	}

	// Map for both date and time patterns
	const day = date.getDate();
	const month = date.getMonth() + 1;
	const year = date.getFullYear();
	const hours24 = date.getHours(); // 0-23
	const hours12 = hours24 % 12 || 12; // 1-12
	const minutes = date.getMinutes();
	const amPm = hours24 < 12 ? 'AM' : 'PM';

	const map = {
		d: day,                               	// Day (1-31)
		dd: String(day).padStart(2, '0'),     	// Day (2 digits)
		M: month,                             	// Month (1-12)
		MM: String(month).padStart(2, '0'),   	// Month (2 digits)
		yyyy: year,                           	// Year (4 digits)
		yy: String(year).slice(-2),           	// Year (2 digits)
		H: hours24,                           	// Hour (0-23)
		HH: String(hours24).padStart(2, '0'), 	// Hour (00-23)
		h: hours12,                           	// Hour (1-12)
		hh: String(hours12).padStart(2, '0'), 	// Hour (01-12)
		m: minutes,                           	// Minute (0-59)
		mm: String(minutes).padStart(2, '0'), 	// Minute (00-59)
		tt: amPm,                             	// AM/PM
	};

	// Replace each token in the format string
	return pattern.replace(/d{1,2}|M{1,2}|yyyy|yy|H{1,2}|h{1,2}|m{1,2}|tt/g, (match) => map[match]);
};

export const useGetDatePatternString = () => {
	const persistedTenant = useSelector((state: RootState) => state.persistedTenant);

	return persistedTenant.value?.datePattern?.pattern || 'dd.MM.yyyy';
}

export const useGetTimePatternString = () => {
	const persistedTenant = useSelector((state: RootState) => state.persistedTenant);

	return persistedTenant.value?.timePattern?.pattern || 'HH:mm';
}

export const useGetDateTimePatternString = () => {
	const datePatternString = useGetDatePatternString();
	const timePatternString = useGetTimePatternString();
	return `${datePatternString} ${timePatternString}`
}

export const useFormatDate = () => {
	const datePatternString = useGetDatePatternString();
	return useCallback(
		(date: Date | undefined) => formatDateByPattern(date, datePatternString),
		[datePatternString]
	);
}

export const useFormatTime = () => {
	const timePatternString = useGetTimePatternString();
	return useCallback(
		(date: Date | undefined) => formatTimeByPattern(date, timePatternString),
		[timePatternString]
	);
}

export const useFormatDateTime = () => {
	const datePatternString = useGetDatePatternString();
	const timePatternString = useGetTimePatternString();
	return useCallback(
		(date: Date | undefined) => `${formatDateByPattern(date, datePatternString)} ${formatTimeByPattern(date, timePatternString)}`,
		[timePatternString, datePatternString]
	);
}

export const formatServerDate = (value?: Date) => {
	if (!value) {
		return '';
	}

	const fullYear = value.getFullYear();
	const month = value.getMonth();
	const dayOfTheMonth = value.getDate();

	return fullYear + '-' +
		(month < 9 ? ('0' + (month + 1)) : (month + 1)) + '-' +
		(dayOfTheMonth < 10 ? ('0' + dayOfTheMonth) : dayOfTheMonth);
}

// const parseTimeStringToMilliseconds = (value: string) => {
// 	const newValueSplit = value.split(':');
// 	const hours = newValueSplit[0] ? parseInt(newValueSplit[0]) : undefined;
// 	const minutes = newValueSplit[1] ? parseInt(newValueSplit[1]) : undefined;

// 	if (hours === undefined || minutes === undefined) {
// 		return;
// 	}

// 	const timeInMinutes = hours * 60 + minutes;
// 	const timeInSeconds = timeInMinutes * 60;
// 	const timeInMiliseconds = timeInSeconds * 1000;
// 	return timeInMiliseconds;
// }

// export const parseTimeStringToTicks = (value: string) => {
// 	const timeInMiliseconds = parseTimeStringToMilliseconds(value);
// 	if (!timeInMiliseconds) {
// 		return;
// 	}
// 	return timeInMiliseconds * 10000;
// }

// TODO: should use new Intl.DateTimeFormat('en-US', { timeStyle: 'short' })
export const formatTime = (value?: number) => {
	if (value === undefined) {
		return '';
	} else {
		const timeInMiliseconds = value / 10000;
		const timeInSeconds = timeInMiliseconds / 1000;
		const timeInMinutes = Math.floor(timeInSeconds / 60);

		const minutes = timeInMinutes % 60;
		let hours = Math.floor(timeInMinutes / 60);
		let amOrPm = 'AM';
		if (hours >= 12) {
			amOrPm = 'PM';
		}

		if (hours > 12) {
			hours -= 12;
		} else if (hours === 0) {
			hours = 12;
		}

		const minutesString = String(minutes).padStart(2, '0');
		const hoursString = String(hours).padStart(2, '0');

		return `${hoursString}:${minutesString} ${amOrPm}`
	}
}

// TODO: should use new Intl.DateTimeFormat('en-US', { timeStyle: 'short' })
export const formatTimeWithoutAmOrPm = (value?: number) => {
	if (value === undefined) {
		return '';
	} else {
		const timeInMiliseconds = value / 10000;
		const timeInSeconds = timeInMiliseconds / 1000;
		const timeInMinutes = Math.floor(timeInSeconds / 60);

		const minutes = timeInMinutes % 60;
		let hours = Math.floor(timeInMinutes / 60);

		const minutesString = String(minutes).padStart(2, '0');
		const hoursString = String(hours).padStart(2, '0');

		return `${hoursString}:${minutesString}`;
	}
}

// export const availableHours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

// export const availableMinutes = [
// 	0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
// 	11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
// 	21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
// 	31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
// 	41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
// 	51, 52, 53, 54, 55, 56, 57, 58, 59
// ];

// export const availableAmPm = [
// 	MeridiemTimeEnum.AM,
// 	MeridiemTimeEnum.PM
// ];

export const convertTicksToMiliSeconds = (value: number) => {
	return value / 10000;
}

export const convertTicksToTime = (value: number) => {
	const timeInMilliSeconds = convertTicksToMiliSeconds(value);
	const timeInSeconds = timeInMilliSeconds / 1000;
	const timeInMinutes = Math.floor(timeInSeconds / 60);

	const minutes = timeInMinutes % 60;
	let hours = Math.floor(timeInMinutes / 60);

	return {
		minutes,
		hours
	};
}

export const convertMinutesToTicks = (minutes: number | undefined) => {
	if (!minutes) {
		return;
	}

	return minutes * 10000 * 1000 * 60;
}

export const convertTicksToMinutes = (ticks: number | undefined) => {
	if (!ticks) {
		return;
	}

	return ticks / 10000 / 1000 / 60;
}

// export const convertTicksToMeridiemTime = (value: number) => {
// 	let { hours, minutes } = convertTicksToTime(value);

// 	const amOrPm = hours >= 12 ? MeridiemTimeEnum.PM : MeridiemTimeEnum.AM;
// 	if (hours > 12) {
// 		hours -= 12;
// 	} else if (hours === 0) {
// 		hours = 12;
// 	}

// 	return {
// 		hours,
// 		minutes,
// 		amOrPm
// 	}
// }

export const convertTimeToTicks = (hours: number, minutes: number) => {
	const timeInMinutes = hours * 60 + minutes;
	const timeInSeconds = timeInMinutes * 60;
	const timeInMilliSeconds = timeInSeconds * 1000;
	const timeInTicks = timeInMilliSeconds * 10000
	return timeInTicks;
}

export const convertMeridiemTimeToTicks = (hours: number, minutes: number, meridiemTime: MeridiemTimeEnum) => {
	if (meridiemTime === MeridiemTimeEnum.PM && hours < 12) {
		hours += 12;
	} else if (meridiemTime === MeridiemTimeEnum.AM && hours === 12) {
		hours = 0;
	}

	const convertedTime = convertTimeToTicks(hours, minutes);
	if (!convertedTime) {
		return;
	}
	return convertedTime;
}

export const minutesToDhm = (minutes: number, dayLastInMinutes: number = 60 * 24) => {
	const d = Math.floor(minutes / dayLastInMinutes);
	const h = Math.floor(minutes % dayLastInMinutes / 60);
	const m = Math.floor(minutes % 60);

	const dDisplay = d > 0 ? d + (d === 1 ? ' day ' : ' days ') : '';
	const hDisplay = h + 'h ';
	const mDisplay = m + 'min ';
	return dDisplay + hDisplay + mDisplay;
}

export const minutesToHm = (minutes: number) => {
	const h = Math.floor(minutes / 60);
	const m = Math.floor(minutes % 60);

	const hDisplay = h > 0 ? h + 'h ' : '';
	const mDisplay = m + 'min ';
	return hDisplay + mDisplay;
}

export const monthsOfYear = [
	{ id: MonthEnum.January, text: 'i18n.january' },
	{ id: MonthEnum.February, text: 'i18n.february' },
	{ id: MonthEnum.March, text: 'i18n.march' },
	{ id: MonthEnum.April, text: 'i18n.april' },
	{ id: MonthEnum.May, text: 'i18n.may' },
	{ id: MonthEnum.June, text: 'i18n.june' },
	{ id: MonthEnum.July, text: 'i18n.july' },
	{ id: MonthEnum.August, text: 'i18n.august' },
	{ id: MonthEnum.September, text: 'i18n.september' },
	{ id: MonthEnum.October, text: 'i18n.october' },
	{ id: MonthEnum.November, text: 'i18n.november' },
	{ id: MonthEnum.December, text: 'i18n.december' },
];

export const getMonthEnumByDate = (date: Date) => monthsOfYear[date.getMonth()].id

export const getMonthIndexByMonthEnum = (monthEnum: MonthEnum) => {
	return monthsOfYear.findIndex(month => month.id === monthEnum);
};

export const daysOfWeek: OptionType[] = [
	{ id: WeekDayEnum.Monday, text: 'i18n.monday' },
	{ id: WeekDayEnum.Tuesday, text: 'i18n.tuesday' },
	{ id: WeekDayEnum.Wednesday, text: 'i18n.wednesday' },
	{ id: WeekDayEnum.Thursday, text: 'i18n.thursday' },
	{ id: WeekDayEnum.Friday, text: 'i18n.friday' },
	{ id: WeekDayEnum.Saturday, text: 'i18n.saturday' },
	{ id: WeekDayEnum.Sunday, text: 'i18n.sunday' },
]

const getDateIndex = (date: Date) => (date.getDay() + 6) % 7;

export const getWeekDayEnumByDate = (date: Date) => {
	const dayIndex = getDateIndex(date);
	return daysOfWeek[dayIndex].id as WeekDayEnum;
}

export const getWeekDayTranslationByDate = (date: Date) => {
	const dayIndex = getDateIndex(date);
	return daysOfWeek[dayIndex].text;

};

export const getMondayOfWeekByDate = (date: Date) => {
	const currentDay = date.getDay();

	let substractDays = currentDay - 1;
	if (substractDays < 0) {
		// Sunday is 0, so we substract 6 days to get monday
		substractDays = 6;
	}
	const monday = new Date(date);
	monday.setDate(monday.getDate() - substractDays);
	monday.setHours(0, 0, 0, 0);
	return monday;
}

export const getDifferenceInDaysFromMonday = (weekDayEnum: WeekDayEnum) => {
	const dayIndex = daysOfWeek.findIndex(day => day.id === weekDayEnum);
	return dayIndex;
};

// removes last character 'Z' from Date string so time is from start of the day
export const removeZoneFromDate = (date: Date | undefined) => {
	if (!date) {
		return;
	}

	const dateIsoString = date.toISOString();
	return new Date(dateIsoString.substring(0, dateIsoString.length - 1));
}

// to quick fix JSON.serialize when we need to send Date without time for example
export const getDateWithTimezoneOffset = (date: Date | undefined) => {
	if (!date) {
		return;
	}

	return new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
}

const getDaysInMonth = (month: number, year: number) => {
	return 32 - new Date(year, month, 32).getDate();
}

const isWeekday = (year: number, month: number, day: number) => {
	const weekDayNumber = new Date(year, month, day).getDay();
	return weekDayNumber !== 0 && weekDayNumber !== 6; // sunday and saturday
}

export const getWeekdaysInMonth = (month: number, year: number) => {
	var days = getDaysInMonth(month, year);
	var weekdays = 0;
	for (var i = 0; i < days; i++) {
		if (isWeekday(year, month, i + 1))
			weekdays++;
	}
	return weekdays;
}

export const getLastDayOfMonth = (date: Date | undefined) => {
	if (!date) {
		return
	}
	return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}
