import React, { memo, useMemo } from 'react';
import { noop } from 'utils/commonHelper';
import { IFormContext, FormContext } from '../../../models';
import { HeaderType } from './Table';
import styles from './table.module.scss';

type Props = {
	headers: HeaderType[]
	fields: React.ReactElement[]
	values: any
}

export const FooterRow = ({ headers, values, fields }: Props) => {
	const context: IFormContext = useMemo(
		() => {
			return {
				values,
				errorsMap: {},
				disabled: true,
				onFieldChange: noop,
				validateField: noop,
				registerValidators: noop
			}
		},
		[values]
	)

	const content = useMemo(
		() => {
			return headers.map((header, index) => (
				<div key={index} className={styles.cell} style={{ flex: header.size }}>
					{values[header.id!] === undefined ? <></> : fields[index]}
				</div>
			));
		},
		[fields, headers, values]
	)

	return (
		<FormContext.Provider value={context}>
			{content}
		</FormContext.Provider>
	);
};

export default memo(FooterRow);
