import { useCallback, useContext, useEffect } from 'react';
import { ErrorsType, ErrorType, FormContext, IFormContext, ValidatorFunctionType } from 'components/Form/models';
import { ADD_VALIDATOR_TYPE, useItemsRegisteredValidatorsReducer } from './useItemsRegisteredValidatorsReducer';
import { isUniqueFieldNameValidator } from './validators';

// not extending FieldProps type, because it is big difference
export type ItemsFieldProps = {
	id: string
	validator?: ValidatorFunctionType
	disabled?: boolean
	uniqueFieldNames?: {id: string, label: string}[]
}

export type ItemsControlCommonProps = {
	fieldId: string
	value?: any[]
	onChange?(index: number, id: string, value: any): void
	disabled?: boolean
	errors: ErrorsType[]
	registerValidators(index: number, id: string, validatorFunctions: Array<ValidatorFunctionType>): void
	validateField(index: number, id: string, value?: any): void
	onDelete(index: number): void
}

export const useItemsField = (props: ItemsFieldProps) => {
	const { id, validator, disabled, uniqueFieldNames } = props;

	const [registeredValidatorsForFieldsFromItems, dispatchRegisteredValidatorsForFieldsFromItems] = useItemsRegisteredValidatorsReducer();
	const context = useContext<IFormContext>(FormContext);
	const registerValidatorsInContext = context.registerValidators; // eslint is complaining (in useEffect dependencies) when using context.registerValidators

	const validateFieldCallback = useCallback(
		(id: string, itemValues: any) => {
			let newError: ErrorType | undefined;
			const currentValue = itemValues[id];

			for (const validator of registeredValidatorsForFieldsFromItems[id] || []) {
				newError = validator(currentValue, itemValues, id);
				if (newError) {
					return newError;
				}
			}

			return;
		},
		[registeredValidatorsForFieldsFromItems]
	)

	const validateFieldsFromAllItemsCallback = useCallback(
		(values: any) => {
			const newValues = values || context.values[id] || [];
			const errors: ErrorsType[] = [];

			for (let i = 0; i < newValues.length; i++) {
				for (const fieldName of Object.keys(registeredValidatorsForFieldsFromItems[i])) {
					const fieldError = validateFieldCallback(fieldName, newValues[i]);
					if (fieldError) {
						if (!errors[i]) {
							errors[i] = {};
						}

						errors[i][fieldName] = fieldError;
					}
				}
			}

			return errors.length === 0 ? undefined : errors;
		},
		[id, context.values, registeredValidatorsForFieldsFromItems, validateFieldCallback]
	)

	// register validators for ItemsField in FormContext, so Form has access to it
	useEffect(
		() => {
			let validatorsToRegister: ValidatorFunctionType[] = []
			validator && validatorsToRegister.push(validator);
			validatorsToRegister.push(validateFieldsFromAllItemsCallback);

			const uniqueFieldNameValidators: ValidatorFunctionType[] = uniqueFieldNames?.map(fieldName => isUniqueFieldNameValidator.bind(null, fieldName.id, fieldName.label)) || [];
			validatorsToRegister = [...validatorsToRegister, ...uniqueFieldNameValidators]

			registerValidatorsInContext && registerValidatorsInContext(id, validatorsToRegister);
			return () => {
				registerValidatorsInContext && registerValidatorsInContext(id, []);
			}
		},
		[id, validator, registerValidatorsInContext, validateFieldsFromAllItemsCallback, uniqueFieldNames]
	)

	// registered validators for item
	const registerValidatorsForItemCallback = useCallback(
		(index: number, id: string, validatorFunctions: Array<ValidatorFunctionType>) => {
			dispatchRegisteredValidatorsForFieldsFromItems({
				type: ADD_VALIDATOR_TYPE,
				index,
				id,
				validatorFunctions
			})
		},
		[dispatchRegisteredValidatorsForFieldsFromItems]
	);

	const itemHasErrorsCallback = useCallback(
		(itemErrors: ErrorsType = {}) => {
			let haveError: boolean = false;
			Object.keys(itemErrors).map((key: string) => {
				if (key && itemErrors[key] !== undefined) {
					haveError = true;
				}
				return null; // just for eslint
			});
			return haveError;
		},
		[]
	)

	const itemsHaveErrorsCallback = useCallback(
		(itemsErrors: ErrorsType[]) => {
			for (const itemErrors of itemsErrors) {
				if (itemErrors && itemHasErrorsCallback(itemErrors)) {
					return true;
				}
			}

			return false;
		},
		[itemHasErrorsCallback]
	)

	const onBlurCallback = useCallback(
		(index: number, fieldName: string, itemValues?: any) => {
			let errors: ErrorsType[] | undefined = (context.errorsMap[id] || []) as ErrorsType[]; // TableField always uses ErrorsType, so safe to cast
			const fieldError = validateFieldCallback(fieldName, itemValues || context.values[id][index]);
			if (fieldError) {
				if (!errors[index]) {
					errors[index] = {};
				}

				errors[index][fieldName] = fieldError;
			} else {
				if (errors[index]) {
					delete errors[index][fieldName];
				}

				const itemsHaveErrors = itemsHaveErrorsCallback(errors);
				if (!itemsHaveErrors) {
					errors = undefined;
				}
			}

			context.setFieldError && context.setFieldError(id, errors);
		},
		[id, context, validateFieldCallback, itemsHaveErrorsCallback]
	)

	const onChangeCallback = useCallback(
		(index: number, fieldName: string, newValue: any) => {
			const itemsValues = [...context.values[id]];
			itemsValues[index] = {
				...itemsValues[index]
			};
			itemsValues[index][fieldName] = newValue;

			context.setFieldValue && context.setFieldValue(id, itemsValues);
			onBlurCallback(index, fieldName, itemsValues[index]);
		},
		[id, context, onBlurCallback]
	)

	const deleteItemCallback = useCallback(
		(index: number) => {
			const newValues = [...context.values[id]];
			newValues.splice(index, 1);
			context.setFieldValue && context.setFieldValue(id, newValues);

			if (context.errorsMap[id]) {
				let newErrors: ErrorsType[] | undefined = [...context.errorsMap[id] as ErrorsType[]]; // TableField always uses ErrorsType, so safe to cast
				newErrors.splice(index, 1);

				const itemsHaveErrors = itemsHaveErrorsCallback(newErrors);
				if (!itemsHaveErrors) {
					newErrors = undefined;
				}
				context.setFieldError && context.setFieldError(id, newErrors);
			}
		},
		[id, context, itemsHaveErrorsCallback]
	)

	return {
		value: context.values[id],
		onChange: onChangeCallback,
		disabled: disabled || context.disabled,
		fieldId: id,
		errors: context.errorsMap[id],
		registerValidators: registerValidatorsForItemCallback,
		validateField: onBlurCallback,
		onDelete: deleteItemCallback,
	} as ItemsControlCommonProps
}
