import { MapItem } from "components/Form/controls/MapPicker/MapPicker/helpers";
import { SemaphoreItemType } from "components/Form/controls/Semaphore/SemaphoreItem";
import { EntityPrefixEnum } from "utils/commonHelper"

/** Type of first argument in Column formatter method */
export type FormatterCell = {
	/** Unformatted value of the cell */
	value: any;
	/** GenericColumnModel concrete object that is passed to the row that contains the cell */
	rowData: any;
	columnId: string;
}

export const enum FooterCalculatorEnum {
	SUM = 'sum'
}

export type ValidatorType = {
	/** If not valid, cell will be marked as invalid and message will be shown in tooltip */
	valid: boolean
	/** If invalid, message should contain explanation displayed in cell tooltip */
	message?: string
}

/** Abstract model of Column. Concrete models extend this one. */
export class GenericColumnModel {
	/** Column id */
	id: string;
	/** Title that is displayed in Column header */
	title: string;
	/** Returns formatted value for display. Table has its fallback formatters based on type of Column, so in most common cases it is not needed to set it. */
	formatter?: (cell: FormatterCell) => string | number | React.ReactElement;
	/** Initial width of column. User can resize that column. */
	width?: number;
	/** Column that needs to be frozen/sticky to the start/end of the table, and not scrolled horizontaly.
	 * For now 'start' value should be set only for first n columns, and 'end' for last m columns (we cannot say 'start' or 'end' for column that is somewhere in the middle) */
	frozen?: 'start' | 'end';
	/** Horizontal align of the Cell content. By default it is 'left'. */
	align?: 'left' | 'center' | 'right';
	/** Defines the first column in the Nested Table, used only for expanding or collapsing child rows. */
	isNested?: boolean;
	/** If cell needs to have specific CSS styles, this method should be used and return object that is passed to style property of Cell component. For example color of Cell content. */
	getStyle?: (value: any) => React.CSSProperties;
	/** Removes sort icon from Column header. */
	disableSort?: boolean;
	/** Removes filter functionality from Column header. */
	disableFilter?: boolean;
	/** Value that will be displayed in footer cell. Footer will be shown only if some column sets footerValue */
	footerValue?: () => number | string;
	/** Calculates footer value. If footerValue is set, this option is ignored */
	footerCalculator?: FooterCalculatorEnum;
	/** Returns formatted value to display in footer. Usually used to format footerCalculator value */
	footerFormatter?: (cell: FormatterCell) => string | number;

	/** Cells inside of this Column are clickable and editable control based on the type of Column will be mounted inside of the Cell */
	editable?: (cell: FormatterCell) => boolean;
	/** Only editable cells are validated and save is blocked until all columns validator successfully passed */
	validator?: (cell: FormatterCell) => ValidatorType;

	constructor(data: GenericColumnModel) {
		this.id = data.id;
		this.title = data.title;
		this.formatter = data.formatter;
		this.width = data.width;
		this.frozen = data.frozen;
		this.align = data.align;
		this.isNested = data.isNested;
		this.editable = data.editable;
		this.getStyle = data.getStyle;
		this.disableSort = data.disableSort;
		this.disableFilter = data.disableFilter;
		this.footerValue = data.footerValue;
		this.footerCalculator = data.footerCalculator;
		this.footerFormatter = data.footerFormatter;
		this.editable = data.editable;
		this.validator = data.validator;
	}
}

export class StringColumnModel extends GenericColumnModel { }
export class DateColumnModel extends GenericColumnModel { }
export class DateTimeColumnModel extends GenericColumnModel { }
export class NumberColumnModel extends GenericColumnModel { }
export class BooleanColumnModel extends GenericColumnModel { }
export class CurrencyColumnModel extends GenericColumnModel { }
export class LinkColumnModel extends GenericColumnModel { }

export class TimeColumnModel extends GenericColumnModel {
	/** If it is not time in a day, but some amount of effort or duration. So we display time span with hours that are unlimited and minutes that are limited to 0-59 */
	isDuration?: boolean;

	constructor(data: TimeColumnModel) {
		super(data);
		this.isDuration = data.isDuration;
	}
}

export class DurationColumnModel extends GenericColumnModel {
	showInDhm?: boolean;

	constructor(data: DurationColumnModel) {
		super(data);
		this.showInDhm = data.showInDhm;
	}
}

export class AttachmentColumnModel extends GenericColumnModel {
	download?: (cell: FormatterCell, attachmentId: number) => Promise<any>
	simple?: boolean

	constructor(data: AttachmentColumnModel) {
		super(data);
		this.download = data.download;
		this.simple = data.simple;
	}
}

export class SemaphoreColumnModel extends GenericColumnModel {
	colors: SemaphoreItemType[];

	constructor(data: SemaphoreColumnModel) {
		super(data);
		this.colors = data.colors;
	}
}

export class FormattedReferenceColumnModel extends GenericColumnModel {
	entityPrefix: EntityPrefixEnum;
	route?: (cell: FormatterCell) => string;
	multiLink?: boolean;

	constructor(data: FormattedReferenceColumnModel) {
		super(data);
		this.entityPrefix = data.entityPrefix;
		this.route = data.route;
		this.multiLink = data.multiLink;
	}
}

// created generic model, so Option, Options and Map can extend it
// (not good if concrete model extends another concrete model, then we would need to take care about order of if/else checks for instanceof)
export class OptionGenericColumnModel<T> extends GenericColumnModel {
	items: T[];
	getItems?: (cell: FormatterCell) => T[];
	getItemId: (option: T) => number | string;
	getItemText: (option: T) => string;
	getItemStyle?: (option: T) => React.CSSProperties;

	constructor(data: OptionGenericColumnModel<T>) {
		super(data);
		this.items = data.items;
		this.getItems = data.getItems;
		this.getItemId = data.getItemId;
		this.getItemText = data.getItemText;
		this.getItemStyle = data.getItemStyle;
	}
}

export class MapOptionColumnModel extends OptionGenericColumnModel<MapItem> { }
export class OptionsColumnModel<T> extends OptionGenericColumnModel<T> { }

export class OptionColumnModel<T> extends OptionGenericColumnModel<T> {
	getText?: (option: T) => string | undefined;

	constructor(data: OptionColumnModel<T>) {
		super(data);
		this.getText = data.getText;
	}
}

// interaction types

export type SortType = {
	columnId?: string
	isAsc?: boolean
}

export type OrFilterType = {
	columnId?: string | undefined
	value?: any | undefined
	operation?: any // TODO:code_improvement GenericFilterOperationEnum
}

export type FilterType = {
	columnId?: string | undefined
	value?: any | undefined
	operation?: any // TODO:code_improvement GenericFilterOperationEnum
	orFilters?: OrFilterType[] | undefined
}

export type FilterSortPageType = {
	offset: number
	limit: number
	/** Used only for NestedCustomTable to handle pagination changes when needed. */
	nestedLimit?: number
	filters?: FilterType[]
	/** If there are some filters outside of the table that need to applied to the table fetch/export requests, but not visible on UI as Active Filters */
	additionalFilters?: FilterType[]
	sort?: SortType
}

export type InteractionManager = InteractionManagerTyped<any>;

// after moving selectedRow to InteractionManager so we can implement deselecting of row after it is deleted,
// we needed to created typed InteractionManager
// as only useInteractionManager really needs T, we decided not to force code to set InteractionManager<any> in 100+ places
// so InteractionManager and InteractionManagerTyped is created
export type InteractionManagerTyped<T> = {
	click?: (rowData: any) => void
	doubleClick?: (rowData: any) => void
	middleClick?: (rowData: any) => void
	reorderColumn?: (columnsOrder: string[] | undefined) => Promise<void>
	resizeColumn?: (columnId: string, size: number | undefined) => Promise<void>
	resetAllColumnWidths?: () => void
	sort?: (columnId: string | undefined) => void
	filter?: (filters: FilterType[]) => void
	changeOffset?: (offset: number) => void
	changeNestedLimit?: (limit: number) => void
	selectedRow: T | undefined
}
